import "swagger-ui-react/swagger-ui.css"
import React from 'react'

import Swagger from "./components/swagger/swagger";
import Markdown from "./components/markdown/markdown";

import {Navigate, Route, Routes} from "react-router-dom";
import Sidebar from "./components/sidebar/sidebar";

function App() {

    return (
        <>
            <div className="ps-3 sticky-top" style={{background: "linear-gradient(to right, #262445, #6E348F)"}}>
                <img className="mt-3 mb-3" style={{height: "35px"}} src="chainml_logo_hub.png"/>
            </div>
            <Sidebar/>
            <Routes>
                <Route exact path="/" element={ <Navigate to="/getting-started" /> }/>
                <Route path="/getting-started" element={<Markdown md_file="doc/md/getting_started.md"/>}/>
                <Route path="/auth" element={<Markdown md_file="doc/md/auth.md"/>}/>
                <Route path="/e2e" element={<Markdown md_file="doc/md/end2end.md"/>}/>
                <Route path="/sample" element={<Markdown md_file="doc/md/sample.md"/>}/>
                <Route path="/api" element={<Swagger url="doc/swagger/openapi.yaml"/>}/>
                <Route path="/sc" element={<Markdown md_file="doc/md/smart_contract.md"/>}/>
                <Route path="/legal" element={<Markdown md_file="doc/md/legal.md"/>}/>
            </Routes>
        </>
    );
}

export default App;
