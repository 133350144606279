import React from "react";
import './sidebar.css'
import {Link} from "react-router-dom";

function Sidebar() {
    return (
        <>
            <div >
                <nav id="sidebarMenu" className="collapse d-lg-block sidebar collapse bg-white">
                    <div className="position-sticky">
                        <div className="list-group list-group-flush mx-3 mt-4">
                            <a href="/getting-started" className="list-group-item list-group-item-action py-2 ripple" aria-current="true">
                                <span>Getting Started</span>
                                <Link to="/getting-started"/>
                            </a>
                            <a href="/auth" className="list-group-item list-group-item-action py-2 ripple" aria-current="true">
                                <span>Authentication</span>
                                <Link to="/auth"/>
                            </a>
                            <a href="/sample" className="list-group-item list-group-item-action py-2 ripple" aria-current="true">
                                <span>Example Service</span>
                                <Link to="/sample"/>
                            </a>
                            <a href="/e2e" className="list-group-item list-group-item-action py-2 ripple" aria-current="true">
                                <span>End to End Tutorial</span>
                                <Link to="/e2e"/>
                            </a>
                            <a href="/sc" className="list-group-item list-group-item-action py-2 ripple" aria-current="true">
                                <span>Smart Contract</span>
                                <Link to="/sc"/>
                            </a>
                            <a href="/api" className="list-group-item list-group-item-action py-2 ripple" aria-current="true">
                                <span>API Reference</span>
                                <Link to="/api"/>
                            </a>
                            <a href="/legal" className="list-group-item list-group-item-action py-2 ripple" aria-current="true">
                                <span>Legal</span>
                                <Link to="/legal"/>
                            </a>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
}

export default Sidebar;


