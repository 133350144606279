import SwaggerUI from "swagger-ui-react";
import React, {Component} from "react";
import './swagger.css'

class Swagger extends Component {
    render() {
        return (
            <>
                <div id="main" className="swagger" >
                    <SwaggerUI url={this.props.url}/>
                </div>
            </>
        );
    }
}

export default Swagger;