import React, {Component} from 'react';
import ReactMarkdown from 'react-markdown';
import './markdown.css'

class Markdown extends Component {

    constructor() {
        super(undefined);
        this.state = {markdown: ''};
    }

    componentDidMount() {
        const filepath = `./${this.props.md_file}`;
        fetch(filepath)
            .then(res => res.text())
            .then(text => {
                this.setState({markdown: text})
            })
            .catch(error => console.error(error));
    }

    render() {
        const {markdown} = this.state;
        return (
            <>
                <div className="markdown">
                    <ReactMarkdown children={markdown}/>
                </div>
            </>
        );
    }
}

export default Markdown;